import { Column, ScreenScroll, Spacer } from "@gigsmart/atorasu";
import { RemoveBenefitsMenu } from "@gigsmart/feature-flags";
import { type AppScreenProps, Redirect } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React from "react";

import { HourlyRateSummary } from "../gig/HourlyRateSummary";
import WorkerApplyFooter from "../gig/WorkerApplyFooter";
import ShiftDescriptionCard from "../shifts/ShiftDescriptionCard";
import ShiftDetailsCard from "../shifts/ShiftDetailsCard";
import W2ShiftsCard from "../shifts/W2ShiftsCard";
import { WorkerBenefitsRow } from "../worker/WorkerBenefitsRow";
import BlockOrganizationCollapsibleRow, {
  showWorkerBlockOrg
} from "../worker/WorkerBlockOrganizations/BlockOrganizationCollapsibleRow";
import AvailableShiftsSummary from "./AvailableShiftsSummary";
import BrowseShiftHeader from "./BrowseShiftHeader";
import BrowseShiftTopAccessory from "./BrowseShiftTopAccessory";
import type { BrowseShiftDetailsScreenQuery } from "./__generated__/BrowseShiftDetailsScreenQuery.graphql";
import type { WorkerBrowseParams } from "./browse.nav";

type Props = AppScreenProps<WorkerBrowseParams, "BrowseShiftDetails">;

export default createSuspendedQueryContainer<
  BrowseShiftDetailsScreenQuery,
  Props
>(
  function BrowseShiftDetailsScreen({ navigation, route, response }) {
    const { gig: gigOrEngagement, gigSeries } = response?.viewer ?? {};
    const gigLike =
      gigSeries?.node ?? gigOrEngagement?.gig ?? gigOrEngagement?.node;
    const isProject = gigLike?.gigType === "PROJECT";
    const isW2 = gigLike?.requiredReportTypes?.includes("EOR_WORKER");

    if (!gigOrEngagement?.gig && !gigOrEngagement?.node && !gigSeries) {
      return <Redirect back />; // not found
    }
    if (isProject) {
      // this screen is specific for Shifts;
      return (
        <Redirect
          screen="BrowseProjectDetails"
          screenParams={route.params}
          replace
        />
      );
    }

    const engagementState = gigOrEngagement?.currentState;
    const workerDistance =
      gigSeries?.gigDistance?.min ?? gigOrEngagement?.workerDistance;
    const estimatedPayment =
      gigSeries?.estimatedPaymentRange ??
      gigOrEngagement?.estimatedPayment?.netPay;

    const handlePrimaryContact = () =>
      navigation.push("BrowseGigRequester", route.params);

    return (
      <ScreenScroll
        testID="available-shift-screen"
        footer={!!gigLike && <WorkerApplyFooter fragmentRef={gigLike} />}
      >
        <BrowseShiftTopAccessory fragmentRef={gigLike} />
        <BrowseShiftHeader fragmentRef={gigLike} onInfo={handlePrimaryContact}>
          <HourlyRateSummary
            variant="requested"
            currentState={engagementState}
            estimatedPayment={estimatedPayment}
            payRate={gigLike?.payRate}
            labelOverride={isW2 ? "W-2 Hourly Rate" : undefined}
            isW2={isW2}
          />
          <AvailableShiftsSummary
            fragmentRef={gigLike}
            workerDistance={workerDistance}
          />
        </BrowseShiftHeader>
        <Spacer />
        <Column gap="standard">
          <ShiftDescriptionCard fragmentRef={gigLike} isBrowsing />
          {isW2 && <W2ShiftsCard fragmentRef={gigLike} />}
          <ShiftDetailsCard fragmentRef={gigLike} />
          {RemoveBenefitsMenu.isDisabled() && <WorkerBenefitsRow />}
          {showWorkerBlockOrg(gigLike, engagementState?.name) && (
            <BlockOrganizationCollapsibleRow
              organizationId={gigLike?.organization?.id ?? ""}
            />
          )}
        </Column>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query BrowseShiftDetailsScreenQuery($id: ID!, $isSeries: Boolean!) {
        viewer {
          ... on Worker {
            gigSeries(id: $id) @include(if: $isSeries) {
              gigDistance {
                min: minDistance
              }
              estimatedPaymentRange {
                min: minAmount
                max: maxAmount
              }
              node {
                ...BlockOrganizationCollapsibleRow_shift
                ...BrowseShiftHeader_shift
                ...ShiftDescriptionCard_shift
                ...ShiftDetailsCard_shift @arguments(isBrowsing: true)
                ...AvailableShiftsSummary_shift
                ...BrowseShiftTopAccessory_shift
                ...WorkerApplyFooter_gigLike
                ...W2ShiftsCard_shift
                requiredReportTypes
                gigType
                payRate
                organization {
                  id
                }
              }
            }
            gig(id: $id) @skip(if: $isSeries) {
              ... on Engagement {
                id
                workerDistance
                currentState {
                  name
                  action
                }
                estimatedPayment {
                  netPay
                }
                gig {
                  requiredReportTypes
                  ...BlockOrganizationCollapsibleRow_shift
                  ...BrowseShiftHeader_shift
                  ...WorkerApplyFooter_gigLike
                  ...ShiftDescriptionCard_shift
                  ...ShiftDetailsCard_shift @arguments(isBrowsing: true)
                  ...AvailableShiftsSummary_shift
                  ...BrowseShiftTopAccessory_shift
                  ...W2ShiftsCard_shift
                  gigType
                  payRate
                  organization {
                    id
                  }
                }
              }
              ... on AvailableGigsEdge {
                workerDistance: distance
                estimatedPayment {
                  netPay
                }
                node {
                  ...BlockOrganizationCollapsibleRow_shift
                  ...BrowseShiftHeader_shift
                  ...WorkerApplyFooter_gigLike
                  ...ShiftDescriptionCard_shift
                  ...ShiftDetailsCard_shift @arguments(isBrowsing: true)
                  ...AvailableShiftsSummary_shift
                  ...BrowseShiftTopAccessory_shift
                  ...W2ShiftsCard_shift
                  ... on GigLike {
                    requiredReportTypes
                  }
                  gigType
                  payRate
                  organization {
                    id
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ route }) => ({
      id: route.params.id,
      isSeries: route.params.id.startsWith("gigsr")
    })
  }
);
