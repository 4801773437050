/**
 * @generated SignedSource<<788a1d1d63c357112ae120d34781635e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccountMode = "BUSINESS" | "PERSONAL" | "%future added value";
export type AdminRole = "ADMIN" | "EDITOR" | "VIEWER" | "%future added value";
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "DRUG_SCREEN" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
export type RequesterOnboardingState = "BASIC_INFO" | "MODE_SELECTION" | "PAYMENT_METHOD" | "SMS_CONFIRMATION" | "%future added value";
export type SmartHireMode = "DISABLED" | "ENABLED" | "UNDETERMINED" | "%future added value";
export type VerificationReportStatus = "CANCELED" | "FAIL" | "NOT_ELIGIBLE" | "NOT_STARTED" | "PASS" | "PENDING" | "REQUIRES_ATTENTION" | "%future added value";
export type WorkerAccessState = "AVAILABLE" | "EN_ROUTE" | "NO_ACTIVE_DEVICES" | "NO_LOCATION" | "REQUIRES_BASIC_INFO" | "REQUIRES_PARENT_CONSENT" | "REQUIRES_SKILLS" | "REQUIRES_SMS_CONFIRMATION" | "REQUIRES_TRANSPORTATION" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type currentUser_user$data = {
  readonly __typename: string;
  readonly accessState?: WorkerAccessState;
  readonly accountMode?: AccountMode | null | undefined;
  readonly devices?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly token: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly displayName: string | null | undefined;
  readonly firstName: string | null | undefined;
  readonly id: string;
  readonly insertedAt: string;
  readonly isApproved?: boolean;
  readonly lastInitial: string | null | undefined;
  readonly lastName: string | null | undefined;
  readonly onboardingStates?: ReadonlyArray<{
    readonly complete: boolean;
    readonly onboardingState: RequesterOnboardingState;
  }>;
  readonly organization?: {
    readonly id: string;
    readonly isApproved: boolean;
    readonly logoUrl: string | null | undefined;
    readonly name: string;
    readonly smartHireMode: SmartHireMode;
    readonly uuid: string;
  };
  readonly overallRating?: number;
  readonly postalCode?: string | null | undefined;
  readonly primaryEmail: {
    readonly address: string;
  } | null | undefined;
  readonly primaryMobile?: {
    readonly number: string;
  } | null | undefined;
  readonly primaryOrganization?: {
    readonly id: string;
    readonly isApproved: boolean;
    readonly logoUrl: string | null | undefined;
    readonly name: string;
    readonly smartHireMode: SmartHireMode;
    readonly uuid: string;
  } | null | undefined;
  readonly profilePhoto: {
    readonly url: string | null | undefined;
  } | null | undefined;
  readonly referralCode?: {
    readonly code: string;
  };
  readonly requester?: {
    readonly id: string;
    readonly uuid: string;
  };
  readonly restrictions: {
    readonly totalCount: number;
  } | null | undefined;
  readonly role?: AdminRole;
  readonly uuid: string;
  readonly verification?: {
    readonly reports: ReadonlyArray<{
      readonly status: VerificationReportStatus;
      readonly type: ReportType;
    }>;
  };
  readonly " $fragmentType": "currentUser_user";
};
export type currentUser_user$key = {
  readonly " $data"?: currentUser_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"currentUser_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v2 = {
  "_isNull": true
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isApproved",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UserMobile",
  "kind": "LinkedField",
  "name": "primaryMobile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ReferralCode",
  "kind": "LinkedField",
  "name": "referralCode",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 50
    },
    {
      "kind": "Literal",
      "name": "query",
      "value": "ORDER BY updatedAt DESC"
    }
  ],
  "concreteType": "UserDevicesConnection",
  "kind": "LinkedField",
  "name": "devices",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserDevicesEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserDevice",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": "token",
              "args": null,
              "kind": "ScalarField",
              "name": "pushRegistrationToken",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "devices(first:50,query:\"ORDER BY updatedAt DESC\")"
},
v8 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "logoUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "smartHireMode",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "currentUser_user",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insertedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastInitial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 0
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "_or": [
              {
                "expiresAt": {
                  "_gt": "-PT0S"
                },
                "removedAt": (v2/*: any*/)
              },
              {
                "expiresAt": (v2/*: any*/),
                "removedAt": (v2/*: any*/)
              }
            ],
            "removedAt": (v2/*: any*/)
          }
        }
      ],
      "concreteType": "UserRestrictionsConnection",
      "kind": "LinkedField",
      "name": "restrictions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "restrictions(first:0,where:{\"_or\":[{\"expiresAt\":{\"_gt\":\"-PT0S\"},\"removedAt\":{\"_isNull\":true}},{\"expiresAt\":{\"_isNull\":true},\"removedAt\":{\"_isNull\":true}}],\"removedAt\":{\"_isNull\":true}})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "profilePhoto",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserEmail",
      "kind": "LinkedField",
      "name": "primaryEmail",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "type": "Admin",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountMode",
          "storageKey": null
        },
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "OnboardingState",
          "kind": "LinkedField",
          "name": "onboardingStates",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onboardingState",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "complete",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "primaryOrganization",
              "plural": false,
              "selections": (v8/*: any*/),
              "storageKey": null
            }
          ],
          "type": "Requester",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "organization",
              "plural": false,
              "selections": (v8/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Requester",
              "kind": "LinkedField",
              "name": "requester",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "OrganizationRequester",
          "abstractKey": null
        }
      ],
      "type": "RequesterLike",
      "abstractKey": "__isRequesterLike"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accessState",
          "storageKey": null
        },
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overallRating",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerVerification",
          "kind": "LinkedField",
          "name": "verification",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "VerificationReport",
              "kind": "LinkedField",
              "name": "reports",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Worker",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
})();

(node as any).hash = "56f5f56541bd17875cd91a81ec0268d3";

export default node;
