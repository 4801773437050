import type { ValueObject } from "@gigsmart/fomu";
import {
  graphql,
  readRelayInlineFragment,
  useRelayMutationPromise
} from "@gigsmart/relay";
import { DateTime, Duration } from "luxon";
import { useCallback } from "react";

import { getDateTimeFromInputValues } from "./timesheetEditHelpers";

import type {
  EngagementStateAction,
  EngagementStateName,
  EngagementStateOverrideInput,
  EngagementTimesheetPaymentStyle,
  SetEngagementTimesheetInput
} from "./__generated__/timesheetEditHooksResetMutation.graphql";
import type { timesheetEditHooksReset_engagement$key } from "./__generated__/timesheetEditHooksReset_engagement.graphql";

type StateOverrides = readonly StateOverrideEdge[];

type StateOverrideEdge =
  | {
      readonly node:
        | {
            readonly __typename: string;
            readonly id: string;
            readonly action: EngagementStateAction;
            readonly name: EngagementStateName;
            readonly transitionedAt: string;
            readonly overridesState?:
              | {
                  readonly id: string;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
    }
  | null
  | undefined;

const setEngagementMutationSpec = graphql`
  mutation timesheetEditHooksResetMutation(
    $input: SetEngagementTimesheetInput!
  ) {
    setEngagementTimesheet(input: $input) {
      engagementTimesheet {
        totalDurationWorked
        editable
        isOverridden
        paymentStyle
        additionalPayment
      }
      engagement {
        ...ShiftRosterTable_engagement
        endsAt
        currentState {
          action
          name
        }
        gig {
          estimatedMileage
          timezone
        }
        inactiveCancelStates: states(
          first: 0
          query: "WHERE action = INACTIVE_CANCEL"
        ) {
          totalCount
        }
        original: timesheet(variant: SYSTEM) {
          isApproved
          estimatedMileage
          totalDurationWorked
          startedCount: states(first: 0, query: "WHERE action = START") {
            totalCount
          }
          ...TimesheetList_timesheet @arguments(overridden: false)
        }
        workerTimesheet: timesheet(variant: WORKER) {
          editable
          isApproved
          isOverridden
          paymentStyle
          estimatedMileage
          totalDurationWorked
          ...TimesheetList_timesheet @arguments(overridden: true)
        }
        requesterTimesheet: timesheet(variant: REQUESTER) {
          editable
          isApproved
          isOverridden
          paymentStyle
          estimatedMileage
          totalDurationWorked
          ...TimesheetList_timesheet @arguments(overridden: true)
        }
        timesheet {
          id
          paymentStyle
          totalDurationWorked
        }
      }
    }
  }
`;

export const useSetEngagementTimesheet = () => {
  const [commit] = useRelayMutationPromise(setEngagementMutationSpec);
  const [resetTimesheet] = useRelayMutationPromise(graphql`
    mutation timesheetEditHooksResetTimesheetMutation(
      $input: RemoveEngagementTimesheetInput!
    ) {
      removeEngagementTimesheet(input: $input) {
        removedTimesheetId @deleteRecord
      }
    }
  `);
  const callback = useCallback(
    async (
      node?: timesheetEditHooksReset_engagement$key | null,
      kind?: "reset" | "remove-time",
      timesheetId?: string
    ) => {
      if (!node || !kind) return;
      if (kind === "reset" && timesheetId) {
        const input = { timesheetId };
        const result = await resetTimesheet({ input });
        return result;
      }
      const input =
        kind === "reset"
          ? resetTimesheetInput(node)
          : kind === "remove-time"
            ? removeTimeInput(node)
            : null;

      return await commit({ input });
    },
    [commit, resetTimesheet]
  );

  return callback;
};

interface UseEditTimesheetOptions {
  engagementId?: string | null;
  timesheetId?: string | null;
  timezone: string;
}

export const useEditTimesheet = ({
  engagementId,
  timesheetId,
  timezone
}: UseEditTimesheetOptions) => {
  const [commit] = useRelayMutationPromise(setEngagementMutationSpec);
  const fn = useCallback(
    async (
      values: ValueObject,
      breaks: number[],
      states: StateOverrides,
      originalStartsAt?: string | undefined
    ) => {
      if (!values || !timesheetId || !engagementId) return;
      const overrides = createTimesheetOverrides(
        values,
        breaks,
        states,
        originalStartsAt,
        timezone
      );

      return await commit({
        input: {
          engagementId,
          paymentStyle: "CALCULATED",
          estimatedMileage: values?.mileage
            ? Number.parseFloat(values?.mileage)
            : null,
          overrides,
          createOverrides: overrides.length > 0
        }
      });
    },
    [commit, engagementId, timesheetId]
  );

  return fn;
};

export function createTimesheetOverrides(
  values: ValueObject,
  breaks: number[],
  states: StateOverrides,
  originalStartsAt: string | undefined,
  timezone: string
) {
  const startEdge = states.find((d) => d?.node?.action === "START");
  const endEdge = states.find(
    (d) => d?.node?.action && ["END", "EXCEED_DURATION"].includes(d.node.action)
  );

  const newStartTime = getDateFromInputValues(
    values.startTimeTime,
    values.startTimeAmpm,
    values.startTimeDate,
    timezone
  );
  const newEndTime = getDateFromInputValues(
    values.endTimeTime,
    values.endTimeAmpm,
    values.endTimeDate,
    timezone
  );

  const overrides: EngagementStateOverrideInput[] = [
    {
      name: "WORKING",
      action: "START",
      transitionedAt: getTransitionedAt(
        newStartTime,
        startEdge,
        originalStartsAt,
        timezone
      ),
      engagementStateId: getStateId(startEdge)
    },
    ...getBreakOverrides({ values, breaks, states, timezone }),
    {
      name: "PENDING_TIMESHEET_APPROVAL",
      action: "END",
      transitionedAt: getTransitionedAt(
        newEndTime,
        endEdge,
        undefined,
        timezone
      ),
      engagementStateId: getStateId(endEdge)
    }
  ];
  return overrides;
}

export const removeTimeInput = (
  engagementFragmentRef: timesheetEditHooksReset_engagement$key
) => ({
  createOverrides: false,
  estimatedMileage: null,
  ...fixedHoursTimesheetInput(
    engagementFragmentRef,
    Duration.fromMillis(0).toISO()
  )
});

export const resetTimesheetInput = (
  engagementFragmentRef: timesheetEditHooksReset_engagement$key
) =>
  createUpdateTimesheetInput(engagementFragmentRef, {
    paymentStyle: "CALCULATED",
    isApproved: false
  });

export const fixedHoursTimesheetInput = (
  engagementFragmentRef: timesheetEditHooksReset_engagement$key,
  fixedHours: string | null
) =>
  createUpdateTimesheetInput(engagementFragmentRef, {
    paymentStyle: "FIXED_HOURS",
    isApproved: false,
    fixedHours
  });

//
// PRIVATE

function createUpdateTimesheetInput(
  engagementFragmentRef: timesheetEditHooksReset_engagement$key,
  {
    paymentStyle: rawPaymentStyle,
    isApproved = true,
    fixedHours
  }: {
    paymentStyle?: EngagementTimesheetPaymentStyle;
    isApproved?: boolean;
    fixedHours?: string | null;
  } = {}
): SetEngagementTimesheetInput {
  const engagement = readRelayInlineFragment(
    graphql`
      fragment timesheetEditHooksReset_engagement on Engagement @inline {
        id
        endsAt
        startsAt
        billingInfo {
          billableDuration
          totalDue
          totalServiceFee {
            minimumAmount
            percentageRate
          }
        }
        id
        endsAt
        startsAt
        billingInfo {
          billableDuration
          totalDue
        }
        timesheet {
          id
          paymentStyle
          totalDurationWorked
        }
      }
    `,
    engagementFragmentRef
  );

  /*
    payment styles:
    - CALCULATED
      - default
    - FIXED_HOURS
      - Need totalDurationWorked
    - FIXED_AMOUNT
      - Need estimatedHoursWorked, totalPaymentAmount
  */
  const paymentStyle =
    rawPaymentStyle ?? engagement?.timesheet?.paymentStyle ?? "CALCULATED";

  // required fields
  const input: SetEngagementTimesheetInput = {
    engagementId: engagement?.id ?? "",
    isApproved,
    paymentStyle
  };

  // optional fields
  if (paymentStyle === "FIXED_AMOUNT") {
    input.totalPaymentAmount = engagement?.billingInfo?.totalDue;
    if (engagement?.billingInfo?.billableDuration) {
      input.estimatedDurationWorked = engagement?.billingInfo?.billableDuration;
    }
  } else if (paymentStyle === "FIXED_HOURS") {
    // Accept "fixedHours" input or automatically computes the total time by
    // using startDate + endsAt Date Engagement props
    let totalDurationWorked: string | undefined | null = fixedHours;
    if (totalDurationWorked === undefined) {
      totalDurationWorked = engagement?.timesheet?.totalDurationWorked;
    }
    if (
      totalDurationWorked === null &&
      engagement?.startsAt &&
      engagement?.endsAt
    ) {
      totalDurationWorked = DateTime.fromISO(engagement.startsAt)
        .diff(DateTime.fromISO(engagement.endsAt))
        .toISO();
    }
    input.totalDurationWorked = totalDurationWorked;
  }

  return input;
}

function getBreakOverrides({
  values,
  breaks,
  states,
  timezone
}: {
  values: ValueObject;
  breaks: number[];
  states: StateOverrides;
  timezone: string;
}): EngagementStateOverrideInput[] {
  if (!values.includeBreaks) return [];
  const overrides: EngagementStateOverrideInput[] = [];
  let allPauses =
    states?.filter((edge) => edge?.node?.action === "PAUSE") || [];
  let allResumes =
    states?.filter((edge) => edge?.node?.action === "RESUME") || [];
  breaks.forEach((entry) => {
    const startTime = getDateFromInputValues(
      values[`break${entry}StartTime`],
      values[`break${entry}StartAmpm`],
      values[`break${entry}StartDate`],
      timezone
    );
    if (startTime) {
      let matchingState = states?.find(
        (edge) =>
          edge?.node?.action === "PAUSE" &&
          startTime.hasSame(
            DateTime.fromISO(edge?.node?.transitionedAt),
            "minute"
          )
      );
      if (!matchingState) {
        matchingState = allPauses[0];
        const stateId = getStateId(matchingState);
        overrides.push({
          name: "PAUSED",
          action: "PAUSE",
          transitionedAt: startTime.toISO(),
          engagementStateId: stateId
        });
      } else {
        const stateId = getStateId(matchingState);
        overrides.push({
          name: "PAUSED",
          action: "PAUSE",
          transitionedAt:
            matchingState?.node?.transitionedAt ?? DateTime.local().toISO(),
          engagementStateId: stateId
        });
      }
      if (matchingState) {
        allPauses = allPauses.filter(
          (edge) => matchingState && edge?.node?.id !== matchingState?.node?.id
        );
      }
    }
    const endTime = getDateFromInputValues(
      values[`break${entry}EndTime`],
      values[`break${entry}EndAmpm`],
      values[`break${entry}EndDate`],
      timezone
    );
    if (endTime) {
      let matchingState = states?.find(
        (edge) =>
          edge?.node?.action === "RESUME" &&
          endTime.hasSame(
            DateTime.fromISO(edge?.node?.transitionedAt),
            "minute"
          )
      );
      if (!matchingState) {
        matchingState = allResumes[0];
        const stateId = getStateId(matchingState);
        overrides.push({
          name: "WORKING",
          action: "RESUME",
          transitionedAt: endTime.toISO(),
          engagementStateId: stateId
        });
      } else {
        const stateId = getStateId(matchingState);
        overrides.push({
          name: "WORKING",
          action: "RESUME",
          transitionedAt:
            matchingState?.node?.transitionedAt ?? DateTime.local().toISO(),
          engagementStateId: stateId
        });
      }
      if (matchingState) {
        allResumes = allResumes.filter(
          (edge) =>
            !!matchingState && edge?.node?.id !== matchingState?.node?.id
        );
      }
    }
  });

  return overrides;
}

function getTransitionedAt(
  newDateTime: DateTime,
  edge?: StateOverrideEdge,
  originalDate?: string,
  timezone?: string
) {
  if (originalDate) {
    const originalDateTime = DateTime.fromISO(originalDate, { zone: timezone });
    if (originalDateTime.hasSame(newDateTime, "minute")) {
      return originalDate;
    }
  }

  if (
    !edge?.node?.transitionedAt ||
    !!edge.node?.overridesState?.id ||
    !DateTime.fromISO(edge.node.transitionedAt, { zone: timezone }).hasSame(
      newDateTime,
      "minute"
    )
  ) {
    return newDateTime.toISO();
  }
  return edge.node.transitionedAt;
}

export function getDateFromInputValues(
  time: string,
  ampm: "AM" | "PM" | null,
  date: DateTime | null | undefined,
  timezone: string
) {
  return getDateTimeFromInputValues(time, ampm, date, timezone);
}

function getStateId(edge?: StateOverrideEdge) {
  const state = edge?.node;
  if (!state) return undefined;
  return state.__typename === "EngagementStateOverride"
    ? state.overridesState?.id ?? null
    : state.id;
}
