import {
  Column,
  ContentArea,
  FooterContent,
  ScreenScroll,
  Text,
  toast
} from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form } from "@gigsmart/fomu";
import type {
  EngagementStateAction,
  EngagementStateName
} from "@gigsmart/isomorphic-shared/gig/helpers";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import { useNegotiateEngagement } from "@gigsmart/seibutsu/engagement/EngagementExtras";
import FomuSubmit from "@gigsmart/seibutsu/fomu/inputs/FomuSubmit";
import { HourlyRateInfo } from "@gigsmart/seibutsu/gig/HourlyRateInfo";
import { HourlyRateInput } from "@gigsmart/seibutsu/gig/HourlyRateInput";
import ShiftDescriptionHeader from "@gigsmart/seibutsu/gig/ShiftDescriptionHeader";
import React, { useMemo } from "react";
import type { WorkerParamList } from "../navigation/types";
import type { NegotiateBidScreenQuery } from "./__generated__/NegotiateBidScreenQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "NegotiateBid">;

export default createSuspendedQueryContainer<NegotiateBidScreenQuery, Props>(
  function NegotiateBidScreen({ navigation, response }) {
    const engagement = response?.node;
    const negotiateEngagement = useNegotiateEngagement();

    const handleSubmit: FomuSubmitFn = async ({ values }, done) => {
      try {
        const engagementId = engagement?.id ?? "";
        const payRate = currency.toISOString(values.hourlyBid);
        await negotiateEngagement({ engagementId, payRate });

        const type = engagement?.capabilities?.find(
          (it) => it.via === "negotiateEngagement"
        )?.type;
        switch (type) {
          case "COUNTER_BID":
            toast.success("Counter-Bid sent");
            break;
          case "BID":
            toast.success("Bid sent");
            break;
          case "MODIFY_BID":
            toast.success("Bid updated");
            break;
          default:
            throw new Error("Unknown negotiation type");
        }

        navigation.pop(2);
      } catch (err) {
        console.warn(err);
        toast.error(err.message);
      } finally {
        done();
      }
    };

    // FIXME: How to get the estimatedPayment for the negotiation?
    const baseEstimatedPayment = currency.toFloat(
      engagement?.estimatedPayment?.netPay
    );
    const basePayRate =
      engagement?.currentState?.negotiation?.payRate ??
      engagement?.estimatedPayment?.payRate;

    const initialValues = useMemo(() => {
      const hourlyBid = currency.toFloat(basePayRate).toFixed(2);
      return { hourlyBid };
    }, [basePayRate]);

    const allowDirty = engagement?.currentState?.name === "BID_REQUESTED";
    const { title, submitLabel, inputLabel, summaryLabel } = screenOptions(
      engagement?.currentState?.name,
      engagement?.currentState?.action
    );

    return (
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        <ScreenScroll
          testID="negotiate-bid-screen"
          color="surface"
          footer={
            <FooterContent bgColor="surface">
              <FomuSubmit
                testID="submit-btn"
                label={submitLabel}
                allowDirty={allowDirty}
              />
            </FooterContent>
          }
        >
          <ContentArea gap="standard">
            <Text variant="header" weight="bold">
              {title}
            </Text>
            <Column gap="compact">
              <ShiftDescriptionHeader
                label={summaryLabel}
                fragmentRef={engagement}
              />
              <HourlyRateInput
                label={inputLabel}
                name="hourlyBid"
                entry={engagement}
                basePayRate={basePayRate}
                baseEstimatedPayment={baseEstimatedPayment}
                minPayRate={engagement?.gig?.minimumWage ?? undefined}
              />
              <HourlyRateInfo />
            </Column>
          </ContentArea>
        </ScreenScroll>
      </Form>
    );
  },
  {
    query: graphql`
      query NegotiateBidScreenQuery($id: ID!) {
        node(id: $id) {
          ... on Engagement {
            id
            insertedAt
            startsAt
            endsAt
            currentState {
              name
              action
              negotiation {
                payRate
              }
            }
            estimatedPayment {
              netPay
              payRate
            }
            capabilities {
              type
              status
              via
            }
            gig {
              minimumWage
            }
            ...ShiftDescriptionHeader_availableGig
          }
        }
      }
    `,
    variables: ({ route }) => ({
      id: route.params.id
    })
  }
);

function screenOptions(
  stateName?: EngagementStateName,
  stateAction?: EngagementStateAction
) {
  if (stateName === "BID_REQUESTED") {
    return {
      title: "Please provide your Bid.",
      submitLabel: "Submit Bid"
    };
  }

  if (stateName === "BID_REVIEW") {
    return {
      title: "Edit your Bid.",
      submitLabel: "Update Bid",
      summaryLabel: "Submitted Bid",
      inputLabel: "Your New Hourly Rate Bid:"
    };
  }
  let title = "Place your Counter-Bid.";
  let submitLabel = "Submit Counter-Bid";
  if (stateName === "OFFERED" && stateAction === "OFFER") {
    title = "Place your Bid.";
    submitLabel = "Submit Bid";
  }

  return {
    title,
    submitLabel
  };
}
