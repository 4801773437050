import {
  Button,
  ContentArea,
  ScreenScroll,
  Spacer,
  Stack
} from "@gigsmart/atorasu";
import { WorkerAttendanceWarnings } from "@gigsmart/feature-flags";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import DeleteAccount from "@gigsmart/seibutsu/account/DeleteAccount";
import useRetryOnFocus from "@gigsmart/seibutsu/nav/useRetryOnFocus";
import AttendanceOverviewCard from "@gigsmart/seibutsu/worker-profile/AttendanceOverviewCard";
import CategoryPositionsCard from "@gigsmart/seibutsu/worker-profile/CategoryPositionsCard";
import DirectHireCard from "@gigsmart/seibutsu/worker-profile/DirectHireCard";
import EducationCard from "@gigsmart/seibutsu/worker-profile/EducationCard";
import LanguagesCard from "@gigsmart/seibutsu/worker-profile/LanguagesCard";
import MilitaryCard from "@gigsmart/seibutsu/worker-profile/MilitaryCard";
import ProfileInfoCard from "@gigsmart/seibutsu/worker-profile/ProfileInfoCard";
import ProfileStrengthCard from "@gigsmart/seibutsu/worker-profile/ProfileStrengthCard";
import QualificationsCard from "@gigsmart/seibutsu/worker-profile/QualificationsCard";
import SettingsCard from "@gigsmart/seibutsu/worker-profile/SettingsCard";
import ShareProfileCard from "@gigsmart/seibutsu/worker-profile/ShareProfileCard";
import TransportationMethodCard from "@gigsmart/seibutsu/worker-profile/TransportationMethodCard";
import VerificationStatusCard from "@gigsmart/seibutsu/worker-profile/VerificationStatusCard";
import WorkHistoryCard from "@gigsmart/seibutsu/worker-profile/WorkHistoryCard";
import { showVerificationIntroductionModal } from "@gigsmart/seibutsu/worker-verification/VerificationIntroductionModal";
import { DateTime } from "luxon";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import type { profileScreenRefreshedQuery } from "./__generated__/profileScreenRefreshedQuery.graphql";

export default createSuspendedQueryContainer<profileScreenRefreshedQuery>(
  function ProfileScreen({ retry, response: { viewer = null } = {} }) {
    const nav = useNavigation<AppNavigationProp<WorkerParamList, "Profile">>();
    useRetryOnFocus(retry);
    return (
      <ScreenScroll testID="worker-profile-screen">
        <ProfileInfoCard workerRef={viewer} />
        <Spacer />
        <Stack>
          {WorkerAttendanceWarnings.isEnabled() && (
            <AttendanceOverviewCard fragmentRef={viewer ?? null} />
          )}
          <ProfileStrengthCard fragmentRef={viewer ?? null} />
          <VerificationStatusCard
            fragmentRef={viewer}
            onShowIntroductionModal={() =>
              showVerificationIntroductionModal({
                onAccept: () => nav.push("GetVerified")
              })
            }
          />
          <DirectHireCard fragmentRef={viewer} />
          <Stack>
            <CategoryPositionsCard fragmentRef={viewer} />
            <QualificationsCard workerRef={viewer} />
          </Stack>
          <WorkHistoryCard
            fragmentRef={viewer ?? null}
            onPressAdd={() => nav.push("ProfileWorkHistory", {})}
            onPressEdit={(workHistoryId) =>
              nav.push("ProfileWorkHistory", { workHistoryId })
            }
          />
          <EducationCard
            fragmentRef={viewer ?? null}
            onPressAdd={() => nav.push("ProfileEducation", {})}
            onPressEdit={(educationId) =>
              nav.push("ProfileEducation", { educationId })
            }
          />
          <TransportationMethodCard fragmentRef={viewer} />
          <LanguagesCard fragmentRef={viewer} />
          <MilitaryCard fragmentRef={viewer} />
          <ShareProfileCard workerdId={viewer?.id} />
          <SettingsCard
            onPressEdit={() => nav.push("ProfileAboutMe")}
            onPressAccountSettings={() => nav.push("ProfileAccount")}
            onPressEmergencyContacts={() =>
              nav.push("ProfileEmergencyContacts")
            }
            onPressBlockedOrganizationManagement={() =>
              nav.push("BlockedOrganizationManagement")
            }
          />
          <ContentArea size="none">
            <Stack size="compact">
              <Button
                label="Sign Out"
                size="large"
                variant="clear"
                outline
                fullWidth
                testID="sign-out-button"
                onPress={() => nav.push("Logout")}
              />
              <DeleteAccount />
              <Spacer />
            </Stack>
          </ContentArea>
        </Stack>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query profileScreenRefreshedQuery(
        $startDate: DateTime
        $endDate: DateTime
      ) {
        viewer {
          ... on Worker {
            id
            firstName
            ...ProfileInfoCard_worker
            ...ProfileStrengthCard_worker
            ...WorkHistoryCard_worker
            ...EducationCard_worker
            ...DirectHireCard_Worker
            ...TransportationMethodCard_Worker
            ...VerificationStatusCard_worker
            ...LanguagesCard_Worker
            ...MilitaryCard_Worker
            ...QualificationCategories_gigFields
            ...CategoryPositionsCard_workerProfile
            ...AttendanceOverviewCard_worker @arguments(
              startDate: $startDate
              endDate: $endDate
            )
          }
        }
      }
    `,
    variables: {
      startDate: DateTime.now().minus({ months: 2 }).toString()
    }
  }
);
