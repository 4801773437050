import { Stack, Text, type UnitSize } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { type ComponentProps, type ReactNode } from "react";

import WorkerAvatar from "./WorkerAvatar";

import type { WorkerThumbnail_worker$key } from "./__generated__/WorkerThumbnail_worker.graphql";

interface Props {
  children?: ReactNode;
  disabled?: boolean;
  size?: UnitSize;
  avatarSize?: ComponentProps<typeof WorkerAvatar>["size"];
  fill?: true | number;
  alignItems?: ComponentProps<typeof Stack>["alignItems"];
  showWorkerGroups?: boolean;
}

export const WorkerThumbnail = ({
  avatarSize = "large",
  disabled,
  displayName,
  fill,
  alignItems = "center",
  size,
  children,
  showWorkerGroups,
  result
}: FragmentContainerInnerComponentProps<WorkerThumbnail_worker$key, Props>) => (
  <Stack horizontal size={size} alignItems={alignItems} fill={fill}>
    <WorkerAvatar
      fragmentRef={result}
      size={avatarSize}
      disabled={disabled}
      showWorkerGroups={showWorkerGroups}
    />
    <Stack size="slim" fill={1}>
      <Text
        color={disabled ? undefined : "primary"}
        weight="bold"
        numberOfLines={1}
      >
        {displayName ?? ""}
      </Text>
      {children}
    </Stack>
  </Stack>
);

export default createRelayFragmentContainer<WorkerThumbnail_worker$key, Props>(
  graphql`
    fragment WorkerThumbnail_worker on Worker {
      ...WorkerAvatar_worker
      displayName
    }
  `,
  WorkerThumbnail
);
