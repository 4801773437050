import React, { type ReactNode } from "react";
import { View } from "react-native";
import { Column, Row, Spacer, type UnitSize, getSpaceUnits } from "../quarks";
import { type Color, useStyles } from "../style";

interface Props {
  children?: ReactNode;
  testID?: string;
  variant?: UnitSize;
  size?: UnitSize;
  color?: Color;
}

export default function QuoteContent({
  children,
  testID,
  variant = "compact",
  color = "background",
  size = "compact"
}: Props) {
  const styles = useStyles(
    ({ getColor }) => ({
      bar: {
        width: getSpaceUnits(size),
        backgroundColor: getColor(color, "fill")
      }
    }),
    [size, color]
  );

  return (
    <Row testID={testID} alignItems="stretch">
      <View style={styles.bar} />
      <Spacer horizontal size={variant} />
      <Column fill>{children}</Column>
    </Row>
  );
}
