import {
  Button,
  Column,
  Constraint,
  FooterSpacer,
  ModalBody,
  ModalFooter,
  Row,
  ScreenScroll,
  ScreenTop,
  Stack,
  Text,
  showModal,
  toast
} from "@gigsmart/atorasu";
import { WorkerAttendanceWarnings } from "@gigsmart/feature-flags";
import { type FomuSubmitFn, Form } from "@gigsmart/fomu";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import CancellationQuestionnaire, {
  useCancelEngagement
} from "@gigsmart/seibutsu/engagement/CancellationQuestionnaire";
import EngagementInfoRow from "@gigsmart/seibutsu/engagement/EngagementInfoRow";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import type { gigCancelScreenEngagementQuery } from "./__generated__/gigCancelScreenEngagementQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "CancelEngagement">;

export default createSuspendedQueryContainer<
  gigCancelScreenEngagementQuery,
  Props
>(
  function GigCancelScreen({ navigation, route, response }) {
    const engagement = response?.node;
    const cancelEngagement = useCancelEngagement(engagement ?? null, "WORKER");

    const handleSubmit: FomuSubmitFn = ({ values }, done) => {
      const isWithdraw = engagement?.currentState?.name === "APPLIED";
      const inlineType =
        engagement?.gigType === "PROJECT" ? "Project" : "Shift";

      const handleSetCancellationReason = async (onComplete: () => void) => {
        try {
          await cancelEngagement(values);

          toast.notice(
            isWithdraw
              ? "Your application has been withdrawn"
              : `You have canceled yourself from the ${inlineType}`
          );

          navigation.reset({
            routes: [
              { name: "Home" },
              { name: "History", params: { tab: "canceled" } },
              { name: "ShiftDetails", params: { id: route.params.id } }
            ]
          });
        } catch (err) {
          if ((err as Error).message !== "canceled") {
            toast.error("Something went wrong");
          }
          done();
        } finally {
          done();
          onComplete();
        }
      };

      showModal({
        eventContext: "Cancellation Reason",
        title: isWithdraw
          ? "Withdraw Application"
          : `Cancel from ${inlineType}`,
        horizontalActions: true,
        children: (close) => {
          return (
            <>
              <ModalBody>
                {isWithdraw ? (
                  <Text>{`Are you sure you want to withdraw your application? You will not be able to apply to this ${inlineType} again.`}</Text>
                ) : (
                  WorkerAttendanceWarnings.select(
                    <Stack>
                      <Text>
                        Are you sure you want to cancel yourself from this
                        Shift? This will be reflected in your Worker profile and
                        will be visible to hiring Organizations. You will also
                        not be able to apply to this Shift again.
                      </Text>
                      <Text>
                        <Text weight="bold">
                          Number of times you have late canceled:{" "}
                        </Text>
                        <Text>
                          {engagement?.worker?.attendanceStats
                            ?.lateCancellationTotal ?? 0}
                        </Text>
                      </Text>
                    </Stack>,
                    <Text>{`Are you sure you want to cancel yourself from this ${inlineType}? You will not be able to apply to this ${inlineType} again.`}</Text>
                  )
                )}
              </ModalBody>
              <ModalFooter horizontal>
                <Row fill gap="standard">
                  <Column fill>
                    <Button
                      onPress={close}
                      outline
                      testID="close-cancel-engagement-btn"
                      label={
                        isWithdraw
                          ? "Don't Withdraw Application"
                          : `Don't cancel from ${inlineType}`
                      }
                    />
                  </Column>
                  <Column fill>
                    <Button
                      onPress={async () =>
                        await handleSetCancellationReason(close)
                      }
                      outline
                      testID="cancel-engagement-btn"
                      label={
                        isWithdraw
                          ? "Withdraw Application"
                          : `Cancel from ${inlineType}`
                      }
                    />
                  </Column>
                </Row>
              </ModalFooter>
            </>
          );
        }
      });
    };

    return (
      <Form onSubmit={handleSubmit}>
        <ScreenScroll testID="gig-cancel-screen">
          <ScreenTop extraSpace />
          <Constraint size="xsmall">
            <Stack>
              <EngagementInfoRow fragmentRef={engagement} />
              <CancellationQuestionnaire
                userType="WORKER"
                question="HAD_ISSUE"
                fragmentRef={engagement}
                viewerRef={response?.viewer ?? null}
              />
            </Stack>
            <FooterSpacer />
          </Constraint>
        </ScreenScroll>
      </Form>
    );
  },
  {
    query: graphql`
      query gigCancelScreenEngagementQuery($id: ID!) {
        viewer {
          ...useAutoBlockSetting_organizationRequester
        }
        node(id: $id) {
          ... on Engagement {
            ...EngagementInfoRow_engagement
            ...CancellationQuestionnaire_engagement
            currentState {
              name
            }
            gigType
            worker {
              attendanceStats {
                lateCancellationTotal
              }
            }
          }
        }
      }
    `,
    variables: ({ route }) => ({ id: route.params.id })
  }
);
