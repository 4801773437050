import {
  type Color,
  ContentArea,
  Divider,
  GridNull,
  HighlightedReminder,
  Icon,
  type IconName,
  IconText,
  Row,
  Stack,
  Text,
  toast
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { useMemo } from "react";
import HomeScreenCard from "../worker/HomescreenCard";
import type { AttendanceOverviewCard_worker$key } from "./__generated__/AttendanceOverviewCard_worker.graphql";

export function AttendanceOverviewCard({
  gigStats,
  attendanceStats
}: FragmentContainerInnerComponentProps<AttendanceOverviewCard_worker$key>) {
  const totalEngagementsScheduled = gigStats?.engagementsScheduled ?? 0;
  const totalEngagementsCompleted = gigStats?.engagementsEnded ?? 0;
  const onTimeArrivals = attendanceStats?.onTime ?? 0;
  const lateArrivals = attendanceStats?.lateArrival ?? 0;
  const lateCancellations = attendanceStats?.lateCancellationTotal ?? 0;
  const noShows = attendanceStats?.noShowTotal ?? 0;
  const hasNegativeAttendanceOutcome =
    lateArrivals > 2 || lateCancellations > 2 || noShows > 2;

  if (totalEngagementsScheduled < 1) {
    return <GridNull />;
  }

  const computedLateArrivalProps = useMemo(() => {
    switch (true) {
      case lateArrivals === 0 && totalEngagementsCompleted > 0:
        return {
          icon: "circle-check",
          color: "success"
        };
      case lateArrivals === 0 && totalEngagementsCompleted === 0:
        return {
          icon: "circle-check",
          color: "neutral"
        };
      case lateArrivals > 0 && lateArrivals < 3:
        return {
          icon: "circle-exclamation",
          color: "danger"
        };
      case lateArrivals >= 3:
        return {
          icon: "circle-exclamation",
          color: "error"
        };
      default:
        return {
          icon: "circle-check",
          color: "neutral"
        };
    }
  }, [lateArrivals, totalEngagementsCompleted]);

  const computedLateCancellationProps = useMemo(() => {
    switch (true) {
      case lateCancellations === 0:
        return {
          icon: "circle-check",
          color: "success"
        };
      case lateCancellations > 0 && lateCancellations < 3:
        return {
          icon: "circle-exclamation",
          color: "danger"
        };
      case lateCancellations >= 3:
        return {
          icon: "circle-exclamation",
          color: "error"
        };
      default:
        return {
          icon: "circle-check",
          color: "neutral"
        };
    }
  }, [lateCancellations]);

  return (
    <HomeScreenCard
      headerTitle="Your Attendance Overview - Last 60 Days"
      headerIcon="user-clock"
      testID="attendance-overview-card"
      onHeaderPress={() => toast.notice("Will be handled in 6861")}
      action={
        <Icon
          name="chevron-right"
          size="small"
          color="primary"
          variant="solid"
        />
      }
    >
      <Stack size="medium">
        <AttendanceRowItem
          title="On-Time Arrivals"
          icon={onTimeArrivals > 0 ? "circle-check" : "circle-exclamation"}
          color={onTimeArrivals > 0 ? "success" : "neutral"}
          count={onTimeArrivals}
        />
        <AttendanceRowItem
          title="Late Arrivals"
          count={lateArrivals}
          icon={computedLateArrivalProps.icon as IconName}
          color={computedLateArrivalProps.color as Color}
        />
        <AttendanceRowItem
          title="Late Cancellations"
          count={lateCancellations}
          icon={computedLateCancellationProps.icon as IconName}
          color={computedLateCancellationProps.color as Color}
        />
        <AttendanceRowItem
          title="No Shows"
          count={noShows}
          icon={noShows === 0 ? "circle-check" : "circle-exclamation"}
          color={noShows === 0 ? "success" : "error"}
        />
        <HighlightedReminder
          icon="circle-exclamation"
          header="Attendance information is visible to hiring Organizations."
          variant={hasNegativeAttendanceOutcome ? "error" : "primary"}
        />
      </Stack>
    </HomeScreenCard>
  );
}

function AttendanceRowItem({
  title,
  icon = "circle-check",
  color = "success",
  count = 0
}: { title: string; icon: IconName; color: Color; count: number }) {
  return (
    <Stack size="medium">
      <ContentArea size="none" variant="compact">
        <Row justifyContent="space-between">
          <IconText icon={icon} color={color} size="small" spacing="compact">
            <Text color="black">{title}</Text>
          </IconText>
          <Text color={color} weight="bold">
            {count}
          </Text>
        </Row>
      </ContentArea>
      <Divider />
    </Stack>
  );
}

export default createRelayFragmentContainer<AttendanceOverviewCard_worker$key>(
  graphql`
    fragment AttendanceOverviewCard_worker on Worker @argumentDefinitions(
      startDate: { type: "DateTime" }
      endDate: { type: "DateTime" }
    ) {
      id
      gigStats {
        engagementsScheduled
        engagementsEnded
      }
      attendanceStats(end: $endDate, start: $startDate) {
        lateArrival
        lateCancellationExcused
        lateCancellationTotal
        lateCancellationUnexcused
        noShowExcused
        noShowTotal
        noShowUnexcused
        onTime
      }
    }
  `,
  AttendanceOverviewCard
);
