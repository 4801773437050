/**
 * @generated SignedSource<<24f199c92c036a5026fb936929b15ccd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "DRUG_SCREEN" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerApplyFooter_gigLike$data = {
  readonly __typename: string;
  readonly gigType: GigType | null | undefined;
  readonly id: string;
  readonly nextGig?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly gigType: GigType | null | undefined;
        readonly id: string;
        readonly pickupEligible: boolean;
        readonly requiredPermissions: ReadonlyArray<string>;
        readonly requiredReportTypes: ReadonlyArray<ReportType>;
        readonly " $fragmentSpreads": FragmentRefs<"WorkerLatestArrivalTimeReminder_gig">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly pickupEligible?: boolean;
  readonly requiredPermissions?: ReadonlyArray<string>;
  readonly requiredReportTypes: ReadonlyArray<ReportType>;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerLatestArrivalTimeReminder_gig">;
  readonly " $fragmentType": "WorkerApplyFooter_gigLike";
};
export type WorkerApplyFooter_gigLike$key = {
  readonly " $data"?: WorkerApplyFooter_gigLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerApplyFooter_gigLike">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredReportTypes",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredPermissions",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupEligible",
  "storageKey": null
},
v5 = {
  "kind": "InlineDataFragmentSpread",
  "name": "WorkerLatestArrivalTimeReminder_gig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestArrivalTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GigState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerApplyFooter_gigLike",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "type": "Gig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "nextGig",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "AvailableGigsConnection",
          "kind": "LinkedField",
          "name": "availableGigs",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AvailableGigsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Gig",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "availableGigs(first:1)"
        }
      ],
      "type": "GigSeries",
      "abstractKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};
})();

(node as any).hash = "54e0d59a34b85e2dc230760fbc34d383";

export default node;
