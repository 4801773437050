import { FancyHeader, Stack, Surface } from "@gigsmart/atorasu";
import { LocationUpdates } from "@gigsmart/feature-flags";
import type { EngagementStateName } from "@gigsmart/isomorphic-shared/gig/helpers";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import CategorySummaryRow from "../gig/SummaryRows/CategorySummaryRow";
import { CrisisSummaryRow } from "../gig/SummaryRows/CrisisSummaryRow";
import GigPositionSummaryRow from "../gig/SummaryRows/GigPositionSummaryRow";
import QualificationSummaryRow from "../gig/SummaryRows/QualificationSummaryRow";
import type { ShiftDetailsCard_shift$key } from "./__generated__/ShiftDetailsCard_shift.graphql";
import WorkerPaymentSummaryRow from "./summary/WorkerPaymentSummaryRow";
import WorkerSummaryLocation from "./summary/WorkerSummaryLocation";
import WorkerVerificationSummaryRow from "./summary/WorkerVerificationSummaryRow";

type Props = {
  workerDistance?: number | null;
  workerStateName?: EngagementStateName;
  engagementId?: string;
  negotiatedPayRate?: string | null;
};

export const ShiftDetailsCard = ({
  id,
  crisis,
  result,
  workerDistance,
  workerStateName,
  engagementId,
  negotiatedPayRate
}: FragmentContainerInnerComponentProps<ShiftDetailsCard_shift$key, Props>) => {
  const isW2 = result?.requiredReportTypes?.includes?.("EOR_WORKER");

  return (
    <Surface>
      <FancyHeader
        icon="business-time"
        title="Details"
        color={isW2 ? "purple" : undefined}
        variant={isW2 ? "purple" : undefined}
      />
      <Stack variant="divider" testID="shift-details-rows">
        {!!crisis?.title && <CrisisSummaryRow crisis={crisis} />}
        <CategorySummaryRow fragmentRef={result} icon="grid" />
        <GigPositionSummaryRow fragmentRef={result} />
        <QualificationSummaryRow nodeId={id} />
        {LocationUpdates.select(
          null,
          <WorkerSummaryLocation
            fragmentRef={result}
            workerDistance={workerDistance}
            workerStateName={workerStateName}
          />
        )}
        <WorkerPaymentSummaryRow
          fragmentRef={result}
          workerStateName={workerStateName}
          engagementId={engagementId}
          negotiatedPayRate={negotiatedPayRate}
        />
        <WorkerVerificationSummaryRow fragmentRef={result} />
      </Stack>
    </Surface>
  );
};

export default createRelayFragmentContainer<ShiftDetailsCard_shift$key, Props>(
  graphql`
    fragment ShiftDetailsCard_shift on GigLike
      @argumentDefinitions(
        isBrowsing: { type: "Boolean", defaultValue: false }
      ) {
      ...CategorySummaryRow_gigLike
      ...GigPositionSummaryRow_gigLike
      ...WorkerSummaryLocation_shift @arguments(isBrowsing: $isBrowsing)
      ...WorkerPaymentSummaryRow_shift
      ...WorkerVerificationSummaryRow_shift
      id
      crisis {
        title
      }
      requiredReportTypes
    }
  `,
  ShiftDetailsCard
);
