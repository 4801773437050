/**
 * @generated SignedSource<<ce54778f8cda256babd4fec255935a9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "DRUG_SCREEN" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BrowseShiftHeader_shift$data = {
  readonly name: string | null | undefined;
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationRatingRow_org">;
  } | null | undefined;
  readonly primaryContact: {
    readonly displayName: string | null | undefined;
  } | null | undefined;
  readonly requiredReportTypes: ReadonlyArray<ReportType>;
  readonly " $fragmentType": "BrowseShiftHeader_shift";
};
export type BrowseShiftHeader_shift$key = {
  readonly " $data"?: BrowseShiftHeader_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrowseShiftHeader_shift">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrowseShiftHeader_shift",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredReportTypes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationRequester",
      "kind": "LinkedField",
      "name": "primaryContact",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Literal",
              "name": "skipPrimaryContact",
              "value": true
            }
          ],
          "kind": "FragmentSpread",
          "name": "OrganizationRatingRow_org"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};

(node as any).hash = "a926a752ef2b91e51f36f66faa083ab1";

export default node;
