import { type Color, Tag, TagContainer } from "@gigsmart/atorasu";
import {
  PositionsAndLocations,
  RecurringSchedule
} from "@gigsmart/feature-flags";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React, { useMemo, type ComponentProps } from "react";

import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import type { GigTags_gigLike$key } from "./__generated__/GigTags_gigLike.graphql";

export type TagEntry = ComponentProps<typeof Tag> & {
  key: string;
};

interface Props {
  additionalTags?: TagEntry[];
  fragmentRef?: GigTags_gigLike$key | null | undefined;
}

export function useGigTags(
  gigLikeRef?: GigTags_gigLike$key | null,
  additionalTags: TagEntry[] = []
) {
  const gigLike = useRelayFragment(
    graphql`
      fragment GigTags_gigLike on GigLike {
        acceptsTips
        estimatedMileage
        gigType
        ... on Gig {
          customTags {
            icon {
              type
              data
            }
            color
            content
          }
          slots
          isRecurring
        }
        ... on GigSeries {
          slots
          boosted
          isRecurring
          customTags {
            icon {
              type
              data
            }
            color
            content
          }
        }
      }
    `,
    gigLikeRef ?? null
  );

  return useMemo(() => {
    const {
      estimatedMileage,
      acceptsTips,
      boosted,
      slots,
      customTags,
      isRecurring,
      gigType
    } = gigLike ?? {};

    const applicableTags: TagEntry[] = [];

    // Boosted Tag should come first
    if (boosted) {
      applicableTags.push({
        key: "is-boosted",
        icon: "chevrons-up",
        color: "emphasized",
        label: "Highlighted"
      });
    }

    if (additionalTags) {
      applicableTags.push(...additionalTags);
    }

    if (isRecurring && RecurringSchedule.isEnabled()) {
      applicableTags.push({
        key: "is-recurring",
        icon: "repeat",
        color: "primary",
        label: PositionsAndLocations.select(
          "Recurring Shift",
          "Recurring Shift Gig"
        ),
        variant: "clear"
      });
    }
    if (gigType !== "PROJECT" && slots) {
      applicableTags.push({
        key: "workers-needed",
        icon: "user",
        color: "highlight",
        label: `${pluralize(slots, "Worker")} Needed`,
        variant: "clear"
      });
    }
    if (estimatedMileage) {
      applicableTags.push({
        key: "mileage",
        label: "Travel / Delivery",
        icon: "truck-fast",
        color: "warning",
        variant: "clear"
      });
    }
    if (customTags?.length) {
      applicableTags.push(
        ...customTags.map((it, idx) => ({
          key: `custom-${idx}`,
          iconType: it.icon.type as any,
          icon: it.icon.data,
          label: it.content,
          color: (it.color as Color) ?? undefined,
          variant: "clear" as const
        }))
      );
    }
    if (acceptsTips) {
      applicableTags.push({
        key: "tips",
        label: "Tips",
        icon: "hand-holding-dollar",
        color: "info",
        variant: "clear"
      });
    }

    return applicableTags;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gigLike, JSON.stringify(additionalTags)]);
}

export default function GigTags({ additionalTags, fragmentRef }: Props) {
  const tags = useGigTags(fragmentRef, additionalTags);
  return tags.length ? (
    <TagContainer
      testID="gig-tags-wrapper"
      variant="none"
      size="none"
      tags={tags.map(({ key, ...props }) => (
        <Tag key={key} testID={key} {...props} />
      ))}
    />
  ) : null;
}
