import ShadowView from "@gigsmart/shadow-view";
import React, {
  type ComponentProps,
  createContext,
  useContext,
  type ReactNode
} from "react";
import { Platform, View, type ViewStyle } from "react-native";
import Pressable from "../quarks/Pressable";
import { useStyles } from "../style";
import { ColorPlacementProvider } from "../style/colorPlacement";
import type { Color } from "../style/theme/colors";
import { InCardDeckProvider, useInCardDeckContext } from "./CardDeck";

type Props = {
  onPress?: ComponentProps<typeof Pressable>["onPress"];
  children?: ReactNode;
  dim?: boolean;
  eventTargetName?: string | null;
  testID?: string;
  highlight?: boolean;
  grow?: boolean;
  hideInnerMargin?: boolean;
  /** @deprecated use `color` prop instead */
  hexColor?: string;
  color?: Color;
  zIndex?: number;
  overflow?: ViewStyle["overflow"];
  onLayout?: ComponentProps<typeof ShadowView>["onLayout"];
} & (
  | {
      onPress: NonNullable<ComponentProps<typeof Pressable>["onPress"]>;
      eventTargetName: string | null;
      testID: string;
    }
  | {
      onPress?: null | undefined;
      eventTargetName?: string | null;
      testID?: string;
    }
);

const InCardContext = createContext(false);
export const useInCardContext = () => useContext(InCardContext);
const InCardProvider = InCardContext.Provider;

export default function Card({
  children,
  onPress,
  dim,
  testID,
  eventTargetName,
  highlight,
  hideInnerMargin,
  grow,
  hexColor,
  color,
  zIndex,
  overflow,
  onLayout
}: Props) {
  const inCardDeck = useInCardDeckContext();
  const inCard = useInCardContext();
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      wrapper: {
        flex: inCardDeck ? 1 : undefined,
        borderRadius: getUnits(1),
        zIndex,
        breakInside: "avoid"
      },
      wrapperHighlight: {
        borderWidth: 1,
        borderColor: getColor("divider", "fill")
      },
      wrapperInner: {
        ...Platform.select({
          android: { overflow: "hidden" }
        })
      },
      card: {
        width: "100%",
        backgroundColor: hexColor ?? getColor(color ?? "surface", "fill"),
        borderRadius: getUnits(1),
        shadowColor: "#000000",
        shadowOpacity: 0.15,
        shadowRadius: getUnits(0.5),
        shadowOffset: { width: 0, height: getUnits(0.5) },
        opacity: dim ? 0.5 : undefined,
        ...Platform.select({
          // add a little padding at the bottom to make shadows visible
          default: { overflow },
          android: {
            marginBottom: hideInnerMargin ? getUnits(0) : getUnits(1),
            overflow: inCard ? "hidden" : overflow
          }
        })
      },
      cardGrow: { flexGrow: 1 },
      cardPressed: { opacity: 0.65 }
    }),
    [dim, inCardDeck, inCard, color, hideInnerMargin, zIndex]
  );

  const content = (pressed: boolean) => (
    <ShadowView
      onLayout={onLayout}
      style={[
        styles.card,
        grow && styles.cardGrow,
        !disabled && pressed && styles.cardPressed
      ]}
    >
      <ColorPlacementProvider color="surface">
        {children}
      </ColorPlacementProvider>
    </ShadowView>
  );

  const disabled = !onPress;
  return (
    <InCardDeckProvider value={false}>
      <InCardProvider value>
        {onPress ? (
          <Pressable
            eventEntityType="Card"
            eventTargetName={eventTargetName}
            disabled={disabled}
            onPress={onPress}
            style={[
              styles.wrapper,
              grow && styles.cardGrow,
              inCard && styles.wrapperInner,
              highlight && styles.wrapperHighlight
            ]}
            accessible={false}
            testID={testID}
          >
            {({ pressed }) => content(pressed)}
          </Pressable>
        ) : (
          <View
            testID={testID}
            style={[
              styles.wrapper,
              grow && styles.cardGrow,
              inCard && styles.wrapperInner,
              highlight && styles.wrapperHighlight
            ]}
          >
            {content(false)}
          </View>
        )}
      </InCardProvider>
    </InCardDeckProvider>
  );
}
