import {
  Button,
  Clipboard,
  Column,
  ContentArea,
  Divider,
  FooterSpacer,
  Icon,
  IconText,
  ScreenScroll,
  ScreenTop,
  Spacer,
  Stack,
  Surface,
  Text,
  toast,
  usePromisedValue,
  useStyles
} from "@gigsmart/atorasu";
import type { AppParamList, AppScreenProps } from "@gigsmart/kaizoku";
import { Card } from "@gigsmart/katana";
import RequesterLogo from "@gigsmart/seibutsu/Brand/RequesterLogo";
import WorkerLogo from "@gigsmart/seibutsu/Brand/WorkerLogo";
import React, { useState } from "react";
import { Platform, TouchableOpacity } from "react-native";
import { useCurrentUser } from "../current-user";

const tiles = {
  Requester: {
    label: "Get Workers",
    text: "Share with people and companies looking for Workers",
    testID: "requester-app-card",
    ImageComponent: RequesterLogo,
    getData: async (referralCode?: string | null, campaign?: string) => {
      const url = await getShortUrl(
        `https://gigsmart.com/contact-sales/?utm_source=${referralCode}&utm_medium=referral${
          campaign ? `&utm_campaign=${campaign}` : ""
        }`
      );
      return {
        url,
        message: `Short on staff? Choose from thousands of workers that meet your businesses' needs. Download the GigSmart Get Workers app: ${url}`
      };
    }
  },
  Worker: {
    label: "Get Gigs",
    text: "Share with people looking for Work",
    testID: "worker-app-card",
    ImageComponent: WorkerLogo,
    getData: async (referralCode?: string | null, campaign?: string) => {
      const url = await getShortUrl(
        `https://gigsmart.com/find-work/get-gigs/?utm_source=${referralCode}&utm_medium=referral${
          campaign ? `&utm_campaign=${campaign}` : ""
        }`
      );
      return {
        url,
        message: `Find jobs with more freedom and flexibility.\n\nThis isn't just an app - it's a whole new way to work. \n\n${url}`
      };
    }
  }
};

async function getShortUrl(originalURL: string) {
  const res = await fetch("https://api.short.io/links/public", {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      authorization: String(process.env.SHORT_IO_API_KEY)
    },
    body: JSON.stringify({
      originalURL,
      domain: String(process.env.SHORT_IO_DOMAIN)
    })
  });
  const json = await res.json();
  console.log(json);
  return json.shortURL;
}

export default function ShareScreen({
  route
}: AppScreenProps<AppParamList, "Share">) {
  const { only, campaign } = route.params ?? {};
  const referralCode = useCurrentUser()?.referralCode?.code;
  const [appName, setAppName] = useState(only);
  const { message, url } = usePromisedValue(
    async () =>
      appName
        ? await tiles[appName].getData(referralCode, campaign)
        : { message: "", url: "" },
    { message: "", url: "" },
    [appName]
  );

  const styles = useStyles(({ getColor }) => ({
    outer: { flex: 1 },
    card: {
      height: 215,
      justifyContent: "center",
      paddingHorizontal: 16
    },
    activeCard: {
      borderColor: getColor("primary", "fill"),
      borderWidth: 2,
      paddingHorizontal: 15
    },
    icon: { padding: 5, alignSelf: "flex-end" },
    check: {
      position: "absolute",
      right: 8,
      top: 6
    }
  }));

  return (
    <ScreenScroll constraint="small" testID="share-screen">
      <ScreenTop />
      <Surface>
        <ContentArea constraint="xsmall">
          <IconText
            icon="square-share-nodes"
            size="large"
            textWeight="bold"
            color="primary"
            spacing="compact"
          >
            {`Share GigSmart${only ? ` ${tiles[only].label}` : ""}!`}
          </IconText>

          <Spacer size="compact" />
          <Text variant="note" color="primary">
            {only
              ? ` ${tiles[only].text}.`
              : "Help us show others the new way to hire Workers or find work."}
          </Text>
          <Spacer />
          {only ? null : (
            <>
              <Stack horizontal={Platform.OS === "web"}>
                {(["Requester", "Worker"] as const).map((id) => {
                  const active = appName === id;
                  const { ImageComponent, testID, label, text } = tiles[id];
                  return (
                    <TouchableOpacity
                      key={id}
                      testID={testID}
                      onPress={() => setAppName(id)}
                      style={styles.outer}
                    >
                      {active && (
                        <Column style={styles.check}>
                          <Icon
                            size="large"
                            name="circle-check"
                            color="primary"
                            variant="solid"
                          />
                        </Column>
                      )}
                      <Card
                        style={[styles.card, active && styles.activeCard]}
                        noMargin
                        noSpacing
                        shadow={false}
                        border
                      >
                        <Column alignItems="center">
                          <ImageComponent />
                          <Spacer />
                          <Text
                            color={id === "Requester" ? "danger" : "primary"}
                            variant="title"
                            align="center"
                          >
                            {label}
                          </Text>
                          <Spacer size="compact" />
                          <Text variant="note" align="center">
                            {text}
                          </Text>
                        </Column>
                      </Card>
                    </TouchableOpacity>
                  );
                })}
              </Stack>
              <Spacer />
            </>
          )}
          {!!appName && (
            <Stack fill={1}>
              <Divider />
              <Button
                testID="email-share-button"
                label="Share with Email"
                icon="square-envelope"
                outline
                color="primary"
                onPress={() =>
                  window.open(
                    `mailto:?subject=${encodeURIComponent(
                      `Check out GigSmart ${tiles[appName].label}`
                    )}&body=${encodeURIComponent(message)}`,
                    "_blank"
                  )
                }
              />
              <Button
                testID="twitter-share-button"
                label="Share on X"
                icon="x-twitter"
                color="primary"
                outline
                onPress={() =>
                  window.open(
                    `https://twitter.com/intent/tweet/?text=${encodeURIComponent(
                      message
                    )}`,
                    "_blank"
                  )
                }
              />
              <Button
                testID="facebook-share-button"
                label="Share with Facebook"
                icon="facebook"
                color="primary"
                outline
                onPress={() =>
                  window.open(
                    `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      url
                    )}&quote=${message}`,
                    "_blank"
                  )
                }
              />
              <Button
                testID="copy-link-button"
                label="Copy Link"
                icon="link"
                color="primary"
                outline
                onPress={() => {
                  Clipboard.setString(url);
                  toast.success("Link copied to clipboard");
                }}
              />
            </Stack>
          )}
        </ContentArea>
      </Surface>
      <FooterSpacer />
    </ScreenScroll>
  );
}
