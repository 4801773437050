import {
  Button,
  Carousel,
  Column,
  ContentArea,
  Divider,
  FancyHeader,
  Surface,
  WysiwygContent
} from "@gigsmart/atorasu";
import { PositionsAndLocations } from "@gigsmart/feature-flags";
import { FadedContainer } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import React, { useState } from "react";
import GigTags from "../gig/GigTags";
import type { TagEntry } from "../gig/GigTags";
import type { ShiftDescriptionCard_shift$key } from "./__generated__/ShiftDescriptionCard_shift.graphql";

type Props = {
  isBrowsing?: boolean;
  showHeader?: boolean;
};

export const ShiftDescriptionCard = ({
  description,
  photos,
  organizationPosition,
  result,
  isBrowsing,
  showHeader = true
}: FragmentContainerInnerComponentProps<
  ShiftDescriptionCard_shift$key,
  Props
>) => {
  const uris = getConnectionNodes(organizationPosition?.photos ?? photos).map(
    (item) => item.url
  );
  const isCollapsible = uris.length || (description ?? "").length >= 140;
  const [expanded, setExpanded] = useState(true);
  const DescWrapper = expanded ? Column : FadedContainer;
  const isW2 = result?.requiredReportTypes?.includes?.("EOR_WORKER");

  const additionalTags = [
    ...(isBrowsing
      ? [
          {
            key: "state",
            label: PositionsAndLocations.select("Shift", "Shift Gig"),
            icon: "business-time",
            color: "warning"
          } as TagEntry
        ]
      : []),
    ...(isW2
      ? [
          {
            key: "state",
            label: "W-2",
            icon: "passport",
            color: "purple",
            variant: "clear"
          } as TagEntry
        ]
      : [])
  ];

  return (
    <Surface>
      {showHeader && (
        <FancyHeader
          icon="info-square"
          title="Description"
          color={isW2 ? "purple" : "primary"}
          variant={isW2 ? "purple" : undefined}
        />
      )}
      <ContentArea gap="standard">
        <GigTags
          fragmentRef={result}
          additionalTags={additionalTags.length ? additionalTags : undefined}
        />
        <DescWrapper>
          <WysiwygContent
            html={`<div style="white-space: pre-wrap">${description}</div>`}
          />
        </DescWrapper>
        {!!uris.length && expanded && (
          <Carousel sources={uris} thumbSize={32.5} />
        )}
      </ContentArea>
      {isCollapsible && <Divider />}
      {isCollapsible && (
        <Button
          testID="show-more-btn"
          size="small"
          variant="clear"
          alignSelf="center"
          onPress={() => setExpanded(!expanded)}
          {...(expanded
            ? { icon: "chevron-up", label: "View Less" }
            : { icon: "chevron-down", label: "View More" })}
        />
      )}
    </Surface>
  );
};

export default createRelayFragmentContainer<
  ShiftDescriptionCard_shift$key,
  Props
>(
  graphql`
    fragment ShiftDescriptionCard_shift on GigLike {
      description
      ...GigTags_gigLike
      requiredReportTypes
      ... on Gig {
        addon(addonType: EOR_WORKERS) {
          addonType
        }
        photos(first: 10) {
          edges {
            node {
              url
            }
          }
        }
      }
      ... on GigSeries {
        organizationPosition {
          photos(first: 10) {
            edges {
              node {
                url
              }
            }
          }
        }
      }
    }
  `,
  ShiftDescriptionCard
);
