import { Column, Text } from "@gigsmart/atorasu";
import React from "react";
import AddonModalContent from "./addon-modal-content";

export default function EorWorkersModalContent() {
  return (
    <AddonModalContent
      introText=""
      bodyContent={
        <Column gap="standard" alignItems="center">
          <Text wrap>
            Hire W-2 Workers, who are employed by Ascen, for your Shifts.
          </Text>
          <Column gap="none" alignItems="center">
            <Text>
              <Text weight="bold">Fee Increase: </Text> 45% (20% increase)
            </Text>
            <Text>Minimum Fee: $25</Text>
          </Column>
        </Column>
      }
      listHeader=""
      learnMoreText="Learn more"
      learnMoreLink="https://gigsmart.com/w2"
      listItems={[]}
    />
  );
}
