/**
 * @generated SignedSource<<1d99a3c642887f1a8bcf4bdef816d538>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigAddonType = "BACKGROUND_CHECKS" | "DIRECT_PAYMENT" | "DRUG_SCREENS" | "EOR_WORKERS" | "EXTEND_PROJECT_GIG" | "GENERAL_LIABILITY_INSURANCE" | "MOTOR_VEHICLE_CHECKS" | "OCCUPATIONAL_ACCIDENT_INSURANCE" | "ONE_WEEK_BOOST" | "SHIFT_GIG_PAYMENTS" | "UNLOCK_PROJECT_GIG" | "%future added value";
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "DRUG_SCREEN" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShiftDescriptionCard_shift$data = {
  readonly addon?: {
    readonly addonType: GigAddonType;
  } | null | undefined;
  readonly description: string | null | undefined;
  readonly organizationPosition?: {
    readonly photos: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly url: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly photos?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly url: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly requiredReportTypes: ReadonlyArray<ReportType>;
  readonly " $fragmentSpreads": FragmentRefs<"GigTags_gigLike">;
  readonly " $fragmentType": "ShiftDescriptionCard_shift";
};
export type ShiftDescriptionCard_shift$key = {
  readonly " $data"?: ShiftDescriptionCard_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftDescriptionCard_shift">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftDescriptionCard_shift",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GigTags_gigLike"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredReportTypes",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "addonType",
              "value": "EOR_WORKERS"
            }
          ],
          "concreteType": "GigAddon",
          "kind": "LinkedField",
          "name": "addon",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "addonType",
              "storageKey": null
            }
          ],
          "storageKey": "addon(addonType:\"EOR_WORKERS\")"
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "GigPhotosConnection",
          "kind": "LinkedField",
          "name": "photos",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GigPhotosEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GigPhoto",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "photos(first:10)"
        }
      ],
      "type": "Gig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationPosition",
          "kind": "LinkedField",
          "name": "organizationPosition",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": (v0/*: any*/),
              "concreteType": "OrganizationPositionPhotosConnection",
              "kind": "LinkedField",
              "name": "photos",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrganizationPositionPhotosEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrganizationPositionPhoto",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "photos(first:10)"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "GigSeries",
      "abstractKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};
})();

(node as any).hash = "70780d12c0b90c8c712606bc09edabe4";

export default node;
