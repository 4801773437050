import { Column, Divider, ListRow, Surface, Text } from "@gigsmart/atorasu";
import {
  type EngagementStateAction,
  type EngagementStateName,
  formatPayRange
} from "@gigsmart/isomorphic-shared/gig/helpers";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import React, { type ReactNode, isValidElement } from "react";
import { type BidStateName, getBidSpec } from "../engagement/EngagementExtras";

type Props = {
  currentState?: {
    action: EngagementStateAction;
    name: EngagementStateName;
  } | null;
  variant?: BidStateName;
  payRate?: string | null;
  estimatedPayment?:
    | number
    | string
    | {
        min?: number | string | null;
        max?: number | string | null;
      }
    | null;
  children?: ReactNode;
  labelOverride?: string;
  isW2?: boolean;
};

export function HourlyRateSummary({
  currentState,
  variant,
  payRate,
  estimatedPayment,
  children,
  labelOverride,
  isW2
}: Props) {
  const bidSpec = getBidSpec(currentState, variant);
  if (!bidSpec) return null;

  const hasChildren = isValidElement(children);
  const content = (
    <Surface
      testID="hourly-rate-summary"
      variant={hasChildren || isW2 ? "flat" : "outline"}
      color={isW2 ? "purple" : bidSpec.tintColor}
      fade
    >
      <ListRow
        label={
          <Text weight="bold" testID="rate-label-txt">
            {labelOverride ?? bidSpec.rateLabel}
          </Text>
        }
        right={
          <Column gap="slim">
            <Text align="right" weight="bold" testID="pay-rate-txt">
              {currency.humanizeRate(payRate)}
            </Text>
            <Text align="right" variant="note" color="neutral">
              Est. Earning:{" "}
              <Text weight="bold" testID="estimated-earnings-txt">
                {formatPayRange(estimatedPayment, { short: false })}
              </Text>
            </Text>
          </Column>
        }
      />
    </Surface>
  );

  return hasChildren ? (
    <Surface variant="outline">
      {content}
      <Divider />
      {children}
    </Surface>
  ) : (
    content
  );
}
