import {
  Button,
  ContentArea,
  HighlightedReminder,
  HighlightedStatement,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { WorkerAttendanceWarnings } from "@gigsmart/feature-flags";
import { useFormSubmit, useFormValues } from "@gigsmart/fomu";
import React from "react";
import type {
  EngagementStateName,
  GigType
} from "./__generated__/CancellationQuestionnaire_engagement.graphql";
import type { CancellationReasonOptionVariant } from "./__generated__/CancellationReasonQuestion_reason.graphql";

interface Props {
  userType: "REQUESTER" | "WORKER";
  workerName: string;
  stateName: EngagementStateName;
  gigType: GigType | null | undefined;
  lateCancellationsCount?: number;
}

const CancellationQuestionnaireSubmit = ({
  userType,
  workerName,
  stateName,
  gigType,
  lateCancellationsCount = 0
}: Props) => {
  const { submit, invalid, submitting } = useFormSubmit();
  const { values } = useFormValues();
  const categoryId = values?.category;
  const optionVariant = values?.[`${categoryId}.option`]?.variant;

  const runningLateNode = userType === "REQUESTER" &&
    stateName === "RUNNING_LATE" && (
      <HighlightedReminder
        icon="circle-exclamation"
        header="You will not be charged a Cancellation Fee for canceling the Worker since they did not arrive to the Shift on time."
      />
    );
  const infoNode =
    userType === "REQUESTER"
      ? getRequesterInfoNode(stateName, workerName, optionVariant)
      : getWorkerInfoNode(gigType, stateName, lateCancellationsCount);

  return (
    <ContentArea size="none">
      <Stack>
        {runningLateNode}
        {infoNode}
        {!!optionVariant && (
          <Button
            testID="submit-questionnaire"
            color="danger"
            onPress={submit}
            disabled={invalid || submitting}
            label={getActionLabel(
              gigType,
              userType,
              stateName,
              optionVariant,
              values?.shouldBlock
            )}
          />
        )}
      </Stack>
    </ContentArea>
  );
};

export default CancellationQuestionnaireSubmit;

export function getActionLabel(
  gigType: Props["gigType"],
  userType: Props["userType"],
  stateName: Props["stateName"],
  variant: CancellationReasonOptionVariant,
  shouldBlock?: string
) {
  if (userType === "WORKER") {
    return stateName === "APPLIED"
      ? "Withdraw Application"
      : `Cancel from ${gigType === "PROJECT" ? "Project" : "Shift"}`;
  }

  const btnLabel =
    variant === "ISSUE_REPORTED"
      ? "Report Issue"
      : variant === "NO_SHOW"
        ? "Report No Show"
        : "Cancel Worker";

  return shouldBlock === "yes" ? `${btnLabel} & Block Worker` : btnLabel;
}

function getRequesterInfoNode(
  stateName: EngagementStateName,
  workerName: string,
  variant?: CancellationReasonOptionVariant
) {
  if (!variant) return null;

  const prefix =
    variant === "NO_SHOW"
      ? `Reporting ${workerName} as a no show`
      : variant === "ISSUE_REPORTED"
        ? `Reporting an issue with ${workerName}`
        : variant === "MISSING_QUALIFICATION"
          ? "Reporting a Worker as having missing Qualifications"
          : `Canceling ${workerName}`;

  const suffix =
    variant !== "NO_SHOW" && stateName === "PENDING_TIMESHEET_APPROVAL"
      ? " Do not cancel this Worker if you owe them compensation."
      : "";

  return (
    <HighlightedReminder
      icon="circle-exclamation"
      header={`${prefix} cannot be undone.${suffix}`}
    />
  );
}

function getWorkerInfoNode(
  gigType: Props["gigType"],
  stateName: Props["stateName"],
  lateCancellationsCount?: number
) {
  if (stateName === "APPLIED") {
    return (
      <HighlightedReminder
        icon="circle-info"
        header="This action cannot be undone."
      />
    );
  }

  const inlineType = gigType === "PROJECT" ? "Project" : "Shift";
  return (
    <HighlightedStatement
      icon="circle-exclamation"
      color="warning"
      iconVariant="solid"
    >
      <Text align="center" weight="bold">
        This action cannot be undone.
      </Text>
      {WorkerAttendanceWarnings.select(
        <Stack>
          <Text align="center">
            Cancelling yourself from a {inlineType} after accepting an offer, or
            not showing to a scheduled {inlineType}, will be shown to hiring
            Organizations and may reduce your chances of being hired for future
            Shifts. You may also be blocked by the Organization.
          </Text>
          <Text align="center">
            <Text weight="bold">Number of times you have late canceled: </Text>
            <Text>{lateCancellationsCount}</Text>
          </Text>
        </Stack>,
        <Text align="center">
          Canceling yourself from a {inlineType} after accepting an offer, or
          not showing to a scheduled {inlineType} may result in being blocked by
          the hiring Organization from working future Shifts for them.
        </Text>
      )}
    </HighlightedStatement>
  );
}
