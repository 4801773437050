import {
  Button,
  Column,
  FancyHeader,
  Surface,
  Text,
  showEmbeddedBrowser
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { W2ShiftsCard_shift$key } from "./__generated__/W2ShiftsCard_shift.graphql";

type Props = {};

export const W2ShiftsCard = ({
  result
}: FragmentContainerInnerComponentProps<W2ShiftsCard_shift$key, Props>) => {
  return (
    <Surface>
      <FancyHeader
        icon="passport"
        title="W-2 Shifts"
        color="purple"
        variant="purple"
      />
      <Column gap="standard">
        <Text weight="bold">You are not currently a W-2 Employee.</Text>
        <Text>
          This Shift is available to W-2 Employees. You can apply, and if the
          Requester offers you the Shift, you'll have the option to enroll as a
          W-2 Employee.
        </Text>
        <Column justifyContent="center">
          <Button
            testID="w-2-learn-more-btn"
            alignSelf="center"
            icon="external-link"
            label="Learn More About W-2 Shifts"
            variant="clear"
            onPress={() =>
              showEmbeddedBrowser({ url: "https://help.gigsmart.com/bid-tips" })
            }
          />
        </Column>
      </Column>
    </Surface>
  );
};

export default createRelayFragmentContainer<W2ShiftsCard_shift$key, Props>(
  graphql`
    fragment W2ShiftsCard_shift on GigLike {
      id
    }
  `,
  W2ShiftsCard
);
