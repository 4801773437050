/**
 * @generated SignedSource<<2d41237d239b8cd9dcb40ff03133c98a>>
 * @relayHash a4cf4571b481e0d5d8fe4c181dbe0955
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:7iUCGXk9QduYTkZHdjUC2X30NKChyShYhKhL1j4cMwY

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type availableGigDetailAltQuery$variables = {
  gigId: string;
};
export type availableGigDetailAltQuery$data = {
  readonly viewer: {
    readonly gig?: {
      readonly id?: string;
      readonly node?: {
        readonly endsAt: string | null | undefined;
        readonly gigType: GigType | null | undefined;
        readonly id: string;
        readonly organization: {
          readonly id: string;
        } | null | undefined;
        readonly payRate: string | null | undefined;
        readonly pickup: {
          readonly eligible: boolean;
          readonly windowEnd: string | null | undefined;
          readonly windowStart: string | null | undefined;
        };
        readonly slots: number | null | undefined;
        readonly startsAt: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"BlockOrganizationCollapsibleRow_shift" | "RequesterInfoRow_gigLike" | "WorkerApplyFooter_gigLike" | "gigDescription_gigLike">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ShiftDescriptionHeader_availableGig" | "gigSummaryRows_availableGig">;
    } | null | undefined;
    readonly id?: string;
  } | null | undefined;
};
export type availableGigDetailAltQuery = {
  response: availableGigDetailAltQuery$data;
  variables: availableGigDetailAltQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gigId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "gigId"
  }
],
v3 = [
  (v1/*: any*/)
],
v4 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "Engagement",
  "abstractKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slots",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eligible",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Pickup",
  "kind": "LinkedField",
  "name": "pickup",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "windowStart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "windowEnd",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRecurring",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestArrivalTime",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedMileage",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredReportTypes",
  "storageKey": null
},
v19 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v21 = [
  (v20/*: any*/),
  (v1/*: any*/)
],
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupEligible",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptsTips",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPay",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "RemoteApplicationTag",
  "kind": "LinkedField",
  "name": "customTags",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RemoteApplicationIcon",
      "kind": "LinkedField",
      "name": "icon",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "data",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v28 = [
  (v27/*: any*/),
  (v1/*: any*/)
],
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v20/*: any*/),
    (v29/*: any*/)
  ],
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastInitial",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overallRating",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 0
    }
  ],
  "concreteType": "EngagementReviewsConnection",
  "kind": "LinkedField",
  "name": "reviewsReceived",
  "plural": false,
  "selections": [
    (v26/*: any*/)
  ],
  "storageKey": "reviewsReceived(first:0)"
},
v35 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v36 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "profilePhoto",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    (v27/*: any*/),
    (v35/*: any*/)
  ],
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredPermissions",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "concreteType": "GigState",
  "kind": "LinkedField",
  "name": "currentState",
  "plural": false,
  "selections": (v21/*: any*/),
  "storageKey": null
},
v39 = [
  (v8/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "availableGigDetailAltQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ShiftDescriptionHeader_availableGig"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "gigSummaryRows_availableGig"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Gig",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "gigDescription_gigLike"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "RequesterInfoRow_gigLike"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "WorkerApplyFooter_gigLike"
                          },
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "BlockOrganizationCollapsibleRow_shift",
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v9/*: any*/),
                                  (v12/*: any*/)
                                ],
                                "type": "GigLike",
                                "abstractKey": "__isGigLike"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "AvailableGigsEdge",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "availableGigDetailAltQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "distance",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Gig",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "negotiable",
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "paymentType",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Pickup",
                            "kind": "LinkedField",
                            "name": "pickup",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "arrivalInstructions",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "area",
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Crisis",
                            "kind": "LinkedField",
                            "name": "crisis",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigCategory",
                            "kind": "LinkedField",
                            "name": "category",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": (v19/*: any*/),
                            "concreteType": "GigSkillsConnection",
                            "kind": "LinkedField",
                            "name": "skills",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigSkillsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigSkill",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Skill",
                                        "kind": "LinkedField",
                                        "name": "skill",
                                        "plural": false,
                                        "selections": (v21/*: any*/),
                                        "storageKey": null
                                      },
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "skills(first:10)"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigPosition",
                            "kind": "LinkedField",
                            "name": "position",
                            "plural": false,
                            "selections": (v21/*: any*/),
                            "storageKey": null
                          },
                          (v22/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigSeries",
                            "kind": "LinkedField",
                            "name": "gigSeries",
                            "plural": false,
                            "selections": [
                              (v23/*: any*/),
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isGigLike"
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementPaymentInfo",
                        "kind": "LinkedField",
                        "name": "estimatedPayment",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "paySchedule",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billableDuration",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "grossPay",
                            "storageKey": null
                          },
                          (v24/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WorkerServiceFee",
                            "kind": "LinkedField",
                            "name": "serviceFees",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hourlyRate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "feeType",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Gig",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v20/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              (v23/*: any*/),
                              (v16/*: any*/),
                              (v13/*: any*/),
                              (v18/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v25/*: any*/),
                                  (v14/*: any*/),
                                  {
                                    "alias": null,
                                    "args": (v19/*: any*/),
                                    "concreteType": "GigPhotosConnection",
                                    "kind": "LinkedField",
                                    "name": "photos",
                                    "plural": false,
                                    "selections": [
                                      (v26/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GigPhotosEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "GigPhoto",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": (v28/*: any*/),
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": "photos(first:10)"
                                  },
                                  (v30/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Requester",
                                    "kind": "LinkedField",
                                    "name": "requester",
                                    "plural": false,
                                    "selections": [
                                      (v31/*: any*/),
                                      (v32/*: any*/),
                                      (v17/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Organization",
                                        "kind": "LinkedField",
                                        "name": "primaryOrganization",
                                        "plural": false,
                                        "selections": [
                                          (v1/*: any*/),
                                          (v20/*: any*/),
                                          (v29/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v33/*: any*/),
                                      (v34/*: any*/),
                                      (v36/*: any*/),
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v37/*: any*/),
                                  (v22/*: any*/),
                                  (v15/*: any*/),
                                  (v38/*: any*/)
                                ],
                                "type": "Gig",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "boosted",
                                    "storageKey": null
                                  },
                                  (v14/*: any*/),
                                  (v25/*: any*/),
                                  {
                                    "alias": null,
                                    "args": (v19/*: any*/),
                                    "concreteType": "GigSeriesPhotosConnection",
                                    "kind": "LinkedField",
                                    "name": "photos",
                                    "plural": false,
                                    "selections": [
                                      (v26/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GigSeriesPhotosEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "GigSeriesPhoto",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": (v28/*: any*/),
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": "photos(first:10)"
                                  },
                                  (v30/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Requester",
                                    "kind": "LinkedField",
                                    "name": "requester",
                                    "plural": false,
                                    "selections": [
                                      (v31/*: any*/),
                                      (v32/*: any*/),
                                      (v17/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Organization",
                                        "kind": "LinkedField",
                                        "name": "primaryOrganization",
                                        "plural": false,
                                        "selections": [
                                          (v20/*: any*/),
                                          (v29/*: any*/),
                                          (v1/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v33/*: any*/),
                                      (v34/*: any*/),
                                      (v36/*: any*/),
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": "nextGig",
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "first",
                                        "value": 1
                                      }
                                    ],
                                    "concreteType": "AvailableGigsConnection",
                                    "kind": "LinkedField",
                                    "name": "availableGigs",
                                    "plural": false,
                                    "selections": [
                                      (v26/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AvailableGigsEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Gig",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v1/*: any*/),
                                              (v9/*: any*/),
                                              (v18/*: any*/),
                                              (v37/*: any*/),
                                              (v22/*: any*/),
                                              (v5/*: any*/),
                                              (v15/*: any*/),
                                              (v38/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": "availableGigs(first:1)"
                                  }
                                ],
                                "type": "GigSeries",
                                "abstractKey": null
                              }
                            ],
                            "type": "GigLike",
                            "abstractKey": "__isGigLike"
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementState",
                                "kind": "LinkedField",
                                "name": "currentState",
                                "plural": false,
                                "selections": [
                                  (v20/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "action",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementNegotiation",
                                    "kind": "LinkedField",
                                    "name": "negotiation",
                                    "plural": false,
                                    "selections": (v39/*: any*/),
                                    "storageKey": null
                                  },
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementPaymentInfo",
                                "kind": "LinkedField",
                                "name": "estimatedPayment",
                                "plural": false,
                                "selections": [
                                  (v24/*: any*/),
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Gig",
                                "kind": "LinkedField",
                                "name": "gig",
                                "plural": false,
                                "selections": (v39/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "Engagement",
                            "abstractKey": null
                          }
                        ],
                        "type": "AvailableGigOrEngagement",
                        "abstractKey": "__isAvailableGigOrEngagement"
                      }
                    ],
                    "type": "AvailableGigsEdge",
                    "abstractKey": null
                  },
                  (v35/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:7iUCGXk9QduYTkZHdjUC2X30NKChyShYhKhL1j4cMwY",
    "metadata": {},
    "name": "availableGigDetailAltQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a8f5271d8c5eca613e9571103b5233d0";

export default node;
