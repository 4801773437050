import React from "react";
import { Row } from "../../quarks";
import { useStyles } from "../../style";
import { CellHeader } from "./CellHeader";
import type {
  NormalizedTableCol,
  TableKey,
  TableSortBy
} from "./TableView.helpers";

type Props<T> = {
  variant?: "default" | "shadow";
  columns: NormalizedTableCol<T>[];
  sortBy: TableSortBy<T>;
  onSort?: (key: TableKey<T>, dir: "asc" | "desc" | null) => void;
};

export function TableHeader<T>({ variant, columns, sortBy, onSort }: Props<T>) {
  const styles = useStyles(({ getUnits, getColor }) => ({
    container: {
      backgroundColor: getColor("foreground", "fill")
    },
    shadow: {
      backgroundColor: getColor("surface", "fill"),
      shadowColor: "#000000",
      shadowOpacity: 0.15,
      shadowRadius: getUnits(0.5),
      shadowOffset: { width: 0, height: getUnits(0.5) }
    }
  }));

  const [sortKey, sortDir] = sortBy;
  const handleSort = (attr: TableKey<T> | null) => {
    if (!attr || !onSort) return;
    // Sort order.
    // 1. not clicked: asc
    // 2. clicked+asc: desc
    // 3. clicked+desc: null
    if (sortKey !== attr || !sortDir) onSort(attr, "asc");
    else onSort(attr, sortDir === "asc" ? "desc" : null);
  };

  const totalWidth = columns.reduce((acc, c) => acc + c.cellWidth, 0);
  return (
    <Row
      alignItems="stretch"
      style={[
        styles.container,
        variant === "shadow" && styles.shadow,
        { width: totalWidth }
      ]}
    >
      {columns.map((it, idx) => (
        <CellHeader
          key={`${idx}-${String(it.attr ?? it.header)}`}
          cellWidth={it.cellWidth}
          label={it.header}
          sort={it.sortable && sortKey === it.attr ? sortDir : null}
          onPress={
            it.attr && it.sortable && onSort
              ? () => handleSort(it.attr)
              : undefined
          }
        />
      ))}
    </Row>
  );
}
