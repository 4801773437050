import {
  Avatar,
  Column,
  ContentArea,
  Stack,
  Text,
  ViewButton
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";

import type { WorkerShiftCardHeader_gigLike$key } from "./__generated__/WorkerShiftCardHeader_gigLike.graphql";

interface Props {
  shiftCount?: number;
  attachment?: JSX.Element | null;
}

export const WorkerShiftCardHeader = ({
  name,
  gigType,
  organization,
  shiftCount = 1,
  attachment
}: FragmentContainerInnerComponentProps<
  WorkerShiftCardHeader_gigLike$key,
  Props
>) => {
  return (
    <>
      {attachment}
      <ContentArea
        size={gigType !== "PROJECT" ? "compact" : "standard"}
        color="foreground"
        topRadius={attachment ? "none" : "small"}
      >
        <Stack horizontal size="medium" alignItems="center">
          {gigType !== "PROJECT" && (
            <Avatar
              size="small"
              color="warning"
              icon="business-time"
              iconSize="large"
              uri={organization?.logoUrl}
              variant="square"
            />
          )}
          <Column gap="slim">
            <Text fill numberOfLines={2} weight="bold">
              {name}
            </Text>
            {/* {requiredReportTypes.includes("EOR_WORKER") && (
              <Tag label="W-2" color="purple" variant="clear" />
            )} */}
          </Column>
          <ViewButton
            testID="view-btn"
            label={shiftCount > 1 ? `View All ${shiftCount}` : "View"}
          />
        </Stack>
      </ContentArea>
    </>
  );
};

export default createRelayFragmentContainer<
  WorkerShiftCardHeader_gigLike$key,
  Props
>(
  graphql`
    fragment WorkerShiftCardHeader_gigLike on GigLike {
      id
      name
      gigType
      organization {
        logoUrl
      }
    }
  `,
  WorkerShiftCardHeader
);
