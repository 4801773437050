import React from "react";
import { Platform, TextInput, type TextInputProps, View } from "react-native";
import IconButton from "../atoms/IconButton";
import Icon from "../quarks/Icon";
import { useStyles } from "../style";
import colors from "../style/theme/colors";
import { fontWeights } from "../style/theme/fontHelpers";

interface Props
  extends Pick<TextInputProps, "value" | "placeholder" | "onChangeText"> {
  testID: string;
  backgroundColor?: "surface" | "foreground";
  fill?: boolean;
  size?: "small" | "default";
}

export default function SearchInput({
  fill,
  value,
  onChangeText,
  placeholder,
  testID,
  backgroundColor = "surface",
  size = "default"
}: Props) {
  const styles = useStyles(
    ({ getColor, getUnits, getFontSize, fonts }) => ({
      container: {
        ...(size === "small"
          ? {
              borderWidth: 1,
              borderColor: getColor("divider", "fill"),
              backgroundColor: "#fff"
            }
          : {
              backgroundColor: getColor(backgroundColor, "fill")
            }),
        borderRadius: getUnits(1),
        flexDirection: "row",
        paddingHorizontal: getUnits(2),
        alignItems: "center",
        flex: fill ? 1 : undefined
      },
      input: {
        ...getFontSize(),
        height: getUnits(size === "small" ? 8 : 11),
        flex: 1,
        padding: getUnits(2),
        color: getColor("textPrimary", "fill"),
        ...Platform.select({
          web: { outlineStyle: "none" }
        }),
        fontFamily: fonts.sansSerif,
        fontWeight: fontWeights.normal
      }
    }),
    [fill]
  );
  return (
    <View style={styles.container} testID={testID}>
      <Icon name="search" size="tiny" color="neutral" variant="solid" />
      <TextInput
        style={styles.input}
        value={value}
        onChangeText={onChangeText}
        placeholder={placeholder}
        placeholderTextColor={colors.input.placement}
      />
      {!!value && (
        <IconButton
          testID={`${testID}-clear-btn`}
          name="times-circle"
          size="small"
          color="neutral"
          variant="solid"
          onPress={() => onChangeText?.("")}
        />
      )}
    </View>
  );
}
