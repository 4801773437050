import { Button, Icon, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { StyledTextList } from "@gigsmart/katana";
import React, { type ReactNode } from "react";
import { Linking, Platform } from "react-native";

interface Props {
  introText?: ReactNode;
  listHeader?: ReactNode;
  learnMoreText: string;
  learnMoreLink: string | undefined;
  bodyContent?: ReactNode;
  listItems?: ReactNode[];
}

export default function AddonModalContent({
  introText,
  listHeader,
  bodyContent,
  listItems,
  learnMoreText,
  learnMoreLink
}: Props) {
  const styles = useStyles(({ getUnits, getColor }) => ({
    list: {
      backgroundColor: getColor("highlight", "fill", { opacity: 0.08 }),
      paddingRight: getUnits(2),
      borderRadius: getUnits(1),
      width: "100%",
      ...Platform.select({
        web: {
          maxWidth: 400,
          alignSelf: "center"
        }
      })
    },
    listHeader: {
      marginTop: getUnits(4)
    }
  }));
  return (
    <>
      {introText && <Text>{introText}</Text>}
      {bodyContent && bodyContent}
      {listHeader && (
        <Text weight="bold" align="center" style={styles.listHeader}>
          {listHeader}
        </Text>
      )}
      {listItems && listItems.length > 0 && (
        <StyledTextList
          bulletChar={
            <Icon name="check" color="success" variant="solid" size="medium" />
          }
          style={styles.list}
          items={listItems}
        />
      )}
      <Button
        icon="circle-question"
        iconPlacement="left"
        variant="clear"
        size="small"
        label={learnMoreText}
        onPress={async () => await Linking.openURL(learnMoreLink ?? "")}
        testID="learn-more-btn"
      />
    </>
  );
}
