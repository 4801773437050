import {
  Column,
  DescriptionItem,
  GridNull,
  Icon,
  Row,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { numeric } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import { showLocation } from "react-native-map-link";
import type { ClockinInfoHeaderRow_orgLocation$key } from "./__generated__/ClockinInfoHeaderRow_orgLocation.graphql";

export const ClockinInfoHeaderRow = ({
  workerDistance,
  gig,
  currentState
}: FragmentContainerInnerComponentProps<ClockinInfoHeaderRow_orgLocation$key>) => {
  const clockinLocation = gig.organizationLocation;
  const showHeaderRow =
    currentState.name === "SCHEDULED" || currentState.name === "EN_ROUTE";

  if (!showHeaderRow) {
    return <GridNull />;
  }

  return (
    <Stack size="none">
      <Text>
        <Text weight="bold">Clock-in Location • </Text>
        <Text>
          {`${numeric.humanize(workerDistance ?? 0, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 1
          })} mi away`}
        </Text>
      </Text>
      <Row gap="slim">
        <Column>
          <DescriptionItem
            title={clockinLocation?.place.streetAddress ?? ""}
            numberOfLines={1}
            onTitlePress={() =>
              showLocation({
                latitude: clockinLocation?.place?.location?.latitude ?? "",
                longitude: clockinLocation?.place?.location?.longitude ?? "",
                googlePlaceId: clockinLocation?.place?.id ?? "",
                alwaysIncludeGoogle: true,
                dialogTitle: "Get Directions",
                dialogMessage: "Select a preferred navigation app"
              })
            }
          />
        </Column>
        <Column justifyContent="center">
          <Icon
            name="diamond-turn-right"
            size="small"
            color="link"
            variant="solid"
          />
        </Column>
      </Row>
    </Stack>
  );
};

export default createRelayFragmentContainer<ClockinInfoHeaderRow_orgLocation$key>(
  graphql`
    fragment ClockinInfoHeaderRow_orgLocation on Engagement {
      id
      workerDistance
      currentState {
        name
      }
      gig {
        organizationLocation {
          place {
            id
            streetAddress
            location {
              latitude
              longitude
            }
          }
        }
      }
    }
  `,
  ClockinInfoHeaderRow
);
